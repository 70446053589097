import React from 'react';
export default function ProductGrid({
    children,
    className,
}: {
    children: any;
    className?: string;
}) {
    return (
        <div
            className={`grid gap-y-10 gap-x-5 md:gap-x-4 lg:gap-x-8 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 ${className}`}
        >
            {children}
        </div>
    );
}
